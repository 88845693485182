import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const ContactPage = ({ data }) => {
  console.log(data);
  let fm = data.allMarkdownRemark.nodes[0].frontmatter;
  let html = data.allMarkdownRemark.nodes[0].html;
  return (
    <Layout>
      <div className="w-full bg-beige">
        <div className="text-center px-6 py-12 mb-6 bg-brown border-b text-beige">
          <h1 className=" text-xl md:text-4xl pb-4">{fm.title}</h1>
          <p className="leading-loose text-gray-dark">{fm.subheading}</p>
        </div>
        <div className="container max-w-4xl mx-auto pb-10 px-12 md:px-0">
          <div className="flex flex-wrap justify-start items-start -mx-4">
            <div className="w-full md:w-1/2 p-4">
              <form
                method="post"
                action="https://formspree.io/"
                className="flex flex-col	"
              >
                <fieldset className="field half first pb-2">
                  <label className="block text-sm text-gray-dark pb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full border rounded py-2 px-3 text-sm text-gray-700"
                  />
                </fieldset>
                <fieldset className="field half pb-2">
                  <label
                    for="email"
                    className="block text-sm text-gray-dark pb-2"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="block w-full border rounded py-2 px-3 text-sm text-gray-700"
                  />
                </fieldset>
                <fieldset className="field half pb-2">
                  <label
                    for="phone"
                    className="block text-sm text-gray-dark pb-2"
                  >
                    Telefonnummer
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="block w-full border rounded py-2 px-3 text-sm text-gray-700"
                  />
                </fieldset>
                <fieldset className="field pb-2">
                  <label
                    for="message"
                    className="block text-sm text-gray-dark pb-2"
                  >
                    Nachricht
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows="6"
                    className="block w-full border rounded py-2 px-3 text-sm text-gray-700"
                  ></textarea>
                </fieldset>
                <fieldset className="flex justify-between">
                  <input className="px-4" type="reset" value="Clear" />
                  <input
                    className="py-2 px-4 font-semibold bg-green cursor-pointer"
                    type="submit"
                    value="Abschicken"
                  />
                </fieldset>
              </form>
            </div>

            <div className="w-full md:w-1/2 p-4 text-brown" dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(contact.md)/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          description
          heading
          slug
          subheading
          title
          image
          date
        }
        html
      }
    }
  }
`;

export default ContactPage;
